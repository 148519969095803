<template src="./ordini.html"></template>


<script>
import DialogDettaglio from "@/components/dialog/ordini/DettaglioOrdine";
import DialogCerca from "@/components/dialog/ordini/CercaOrdini";
import DialogVociExtra from "@/components/dialog/ordini/VociExtraOrdine";
import DialogAllegati from "@/components/dialog/ordini/Allegati";
import DialogCercaClienti from "@/components/dialog/ordini/CercaClienti";
import DialogSconti from "@/components/dialog/ordini/Sconti";
import DialogSerie from "@/components/dialog/ordini/Serie";
import DialogCosto from "@/components/dialog/ordini/Costo";
import DialogDatiTestata from "@/components/dialog/ordini/DatiTestata";
import DialogGeneraOrdine from "@/components/dialog/ordini/GeneraOrdine";
import DialogMail from "@/components/dialog/ordini/InviaMail";
import DialogTesti from "@/components/dialog/ordini/Testi";
import DialogListino from "@/components/dialog/ordini/CambioListino";
import DialogAbbinaInfoAzienda from "@/components/dialog/ordini/AbbinaOrdInfoAzienda";
import DialogTimeLine from "@/components/dialog/ordini/Timeline";
import DialogImpostazioni from "@/components/dialog/ordini/Impostazioni";

import {
  new_ordine,
  del_ordine,
  duplica_ordine,
  get_dettaglioOrdine,
  del_dettaglioOrdine,
  get_vociExtraOrdine,
  del_rigoVociExtra,
  get_varianteDettaglio,
  save_testataOrdine,
  get_datiOrdine,
  get_allegatiOrdine,
  del_allegatiOrdine,
  get_totaliTestata,
  dup_dettaglioOrdine,
  get_stampaOrdine,
  get_stampaCheckAvvisi,
  get_riepilogoSerie,
  get_initOrdine,
  save_testiOrdine,
  get_spostaDettaglio,
  set_convertiOrdine,
  get_articoliObsoletiConfermaOrdine,
  get_annullaConfermaOrdine,
} from "@/api/api.ordini.js";

export default {
  components: {
    DialogDettaglio,
    DialogCerca,
    DialogVociExtra,
    DialogAllegati,
    DialogCercaClienti,
    DialogSconti,
    DialogSerie,
    DialogCosto,
    DialogDatiTestata,
    DialogGeneraOrdine,
    DialogMail,
    DialogTesti,
    DialogListino,
    DialogAbbinaInfoAzienda,
    DialogTimeLine,
    DialogImpostazioni,
  },
  data: () => ({
    fabMenu: true,

    tabsName: [
      "Dettaglio",
      "Voci extra",
      "Riepilogo serie",
      "Allegati",
      "Note preventivo",
    ],
    tabs: null,

    datiTestata: {},
    datiDettaglio: [],
    datiVociExtra: [],
    datiAllegati: [],
    datiSerie: [],
    varianti: [],
    trasmittanzaLimiti: [],
    datiTesti: [],

    pagination: {
      sortBy: "Prog",
    },

    headersDettaglio: [
      { text: " ", value: "", sortable: false }, // Immagine
      { text: "Pos.", value: "Prog" },
      { text: "Riferimento", value: "Riferimento" },
      { text: "Serie", value: "DescSerie" },
      { text: "Q.tà", value: "Qta" },
      { text: "L", value: "L" },
      { text: "H", value: "H" },
      { text: "Progetto", value: "CodProgetto" },
      { text: "Prezzo", value: "Prezzo" },
      { text: "Importo", value: "Importo" },
      { text: "Uw", value: "", sortable: false },
    ],
    headersVociExtra: [
      { text: "Pos.", value: "Prog" },
      { text: "Descrizione", value: "Descrizione" },
      { text: "Q.tà", value: "Qta" },
      { text: "Prezzo", value: "Prezzo" },
      { text: "Importo", value: "Importo" },
      { text: "Sconto", value: "Sconto" },
    ],
    headersAllegati: [
      { text: "Pos.", value: "Prog" },
      { text: "Descrizione", value: "Descrizione" },
      { text: "Documento", value: "Documento" },
    ],
    headersSerie: [
      { text: "Pos.", value: "Prog" },
      { text: "Descrizione", value: "Descrizione" },
      { text: "Prezzo", value: "Prezzo" },
      { text: "Sconto %", value: "Percentuale" },
      { text: "Importo", value: "Importo" },
      { text: "Serie", value: "CodSerie" },
    ],

    dialogDett: false,
    dialogCerca: false,
    dialogVociExtra: false,
    dialogAllegati: false,
    dialogCercaClienti: false,
    dialogSconti: false,
    dialogSerie: false,
    dialogCosto: false,
    dialogDatiTestata: false,
    dialogGeneraOrdine: false,
    dialogMail: false,
    dialogTesti: false,
    dialogListino: false,
    dialogAbbinaInfoAzienda: false,
    dialogTimeline: false,
    dialogImpostazioni: false,

    idPrev: 0,
    scheda: 0,
    progDett: 0,
    progVociExtra: 0,
    progAllegati: 0,
    progSerie: 0,

    selectedRowDettaglio: {},
    selectedRowVociExtra: {},
    selectedRowAllegati: {},
    selectedRowSerie: {},

    expandedRowsDettaglio: [],

    date: null,
    dateFormatted: null,
    menu1: false,
    menu2: false,

    stampe: [],

    loadingDettaglio: false,
    loadingPrint: false,
    loadingDuplica: false,
    loadingVarianti: false,
    imgDettForceUpdate: "",

    tooltipHeaderShow: false,
    tooltipNoteShow: false,

    anagClienteUtilita: false,
  }),

  methods: {
    async init() {
      let res = await get_initOrdine(this.isOrdine());

      this.stampe = res.data.Stampe;
    },

    // --------------------------------------------------------
    // ----------------------- Ordine -------------------------
    // --------------------------------------------------------

    async nuovoOrdine() {
      let confirm = await this.$confirm.open(
        "Nuovo",
        "Vuoi creare un nuovo " +
        (this.isOrdine() ? "ordine" : "preventivo") +
        " ?"
      );

      if (!confirm) return;

      // Pulizia
      this.pulisciOrdine();

      try {
        let res = await new_ordine(this.isOrdine());

        await this.caricaDati(res.data.IDPrev);

        // Apro la ricerca cliente
        this.cercaCliente();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async eliminaOrdine() {
      if (!this.controllaNumero()) return;
      if (this.bloccato) return;
      if (this.ordineImportato) {
        this.$toast.info(
          "Prima di proseguire è necessario disabbinare gli ordini InfoAzienda"
        );
        return;
      }

      for (let i = 1; i <= 2; i++) {
        let confirm = await this.$confirm.open(
          "Elimina",
          i == 1
            ? "Vuoi eliminare il documento ?"
            : "Sei sicuro di eliminare il documento ?"
        );

        if (!confirm) return;
      }

      // Attesa
      this.$wait.open();

      try {
        let res = await del_ordine(this.idPrev);

        // Pulizia
        this.pulisciOrdine();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.$wait.close();
    },

    async duplicaOrdine() {
      if (!this.controllaNumero()) return;

      let confirm = await this.$confirm.open(
        "Duplica",
        "Vuoi duplicare il documento ?"
      );

      if (!confirm) return;

      // Attesa
      this.loadingDuplica = true;

      try {
        let res = await duplica_ordine(this.idPrev);

        await this.caricaDati(parseInt(res.data));
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.loadingDuplica = false;
    },

    async stampaOrdine(tipo) {
      if (!this.controllaNumero()) return;

      // Controllo errore riga zero
      if (this.rigaDettZero) return

      this.loadingPrint = true;

      try {
        // Controllo avvisi
        let resAvvisi = await get_stampaCheckAvvisi(this.idPrev);
        let avvisi = resAvvisi.data;
        if (avvisi) {
          avvisi = await this.$confirm.open(
            "Stampa avvisi",
            "Sono presenti avvisi, vuoi stamparli  ?"
          );
        }

        // Su stampa costi eseguoe delle verifiche
        if (tipo.toLowerCase() == "costi".toLowerCase()) {

          // Verifico se ci sono articoli obsoleti
          let resArtObs = await get_articoliObsoletiConfermaOrdine(this.idPrev)
          if (resArtObs.data.length > 0) {
            let resObsoletiConf = await this.$confirm.open(
              "Articoli",
              "Sono presenti articoli obsoleti, la stampa costi non sarà aggiornata, vuoi proseguire  ?"
            );
            if (!resObsoletiConf) {
              this.loadingPrint = false;
              return
            }
          }

          // Verifico utilizzo di libera
          let resLibera = await get_stampaCheckAvvisi(this.idPrev + "/" + tipo)

          if (resLibera.data) {

            let resLiberaConf = await this.$confirm.open(
              "Libera",
              "Le varianti LIBERE non verranno visualizzate nei costi, vuoi proseguire  ?"
            );
            if (!resLiberaConf) {
              this.loadingPrint = false;
              return
            }
          }
        }


        let res = await get_stampaOrdine(
          tipo + "/" + this.idPrev + "/" + avvisi
        );

        window.open(res.data, "_blank");
      } catch (err) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(err));
      }

      this.loadingPrint = false;
    },

    cercaOrdini() {
      this.dialogCerca = true;
    },

    scorri(tipo) {
      this.caricaDati(this.idPrev, tipo);
    },

    pulisciOrdine() {
      // Reset
      this.datiTestata = {};
      this.datiDettaglio = [];
      this.datiVociExtra = [];
      this.datiAllegati = [];
      this.datiSerie = [];
      this.trasmittanzaLimiti = [];
      this.datiTesti = [];
      this.varianti = [];

      this.idPrev = 0;

      this.scheda = 1;

      this.progDett = 0;
      this.selectedRowDettaglio = {};
      this.imgDettForceUpdate = Math.random();

      this.progVociExtra = 0;
      this.selectedRowVociExtra = {};

      this.progAllegati = 0;
      this.selectedRowAllegati = {};

      this.progSerie = 0;
      this.selectedRowSerie = {};

      // Chiude eventuali righe espanse
      this.expandedRowsDettaglio = [];
    },

    async salvaTestata() {
      // Controllo numero
      if (!this.controllaNumero()) return;

      try {
        let res = await save_testataOrdine(this.datiTestata);

        this.datiTestata = res.data;

        // Devo ricaricare i dati perchè possono cambiare gli sconti
        this.refresh();
      } catch (err) {
        console.log(this.$i2kFunctions.getErrorMsg(err));
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(err));
      }
    },

    async salvaNote() {
      // Controllo numero
      if (!this.controllaNumero()) return;

      let res = await save_testiOrdine(this.idPrev, this.datiTesti);
    },

    async caricaTotali() {
      let res = await get_totaliTestata(this.idPrev);

      this.datiTestata.TotGenerale = res.data.TotGenerale;
      this.datiTestata.Totale = res.data.Totale;
      this.datiTestata.TotIntegrazioni = res.data.TotIntegrazioni;
      this.datiTestata.TotSconti = res.data.TotSconti;
      this.datiTestata.Sconto = res.data.Sconto;
      this.datiTestata.TotQta = res.data.TotQta;
      this.datiTestata.TotMq = res.data.TotMq;
    },

    sconti() {
      // Controllo numero
      if (!this.controllaNumero()) return;

      this.dialogSconti = true;
    },

    costo() {
      // Controllo numero
      if (!this.controllaNumero()) return;

      this.dialogCosto = true;
    },

    cercaCliente(fromUtilita) {
      // Controllo numero
      if (fromUtilita == false && !this.controllaNumero(true)) return;

      if (this.bloccato) return;

      this.anagClienteUtilita = fromUtilita

      this.dialogCercaClienti = true;
    },

    apriDatiTestata() {
      // Controllo numero
      if (!this.controllaNumero(true)) return;

      this.dialogDatiTestata = true;
    },

    generaOrdine() {
      // Controllo numero
      if (!this.controllaNumero()) return;

      this.dialogGeneraOrdine = true;
    },

    inviaMail() {
      // Controllo numero
      if (!this.controllaNumero()) return;

      this.dialogMail = true;
    },

    visualizzaVarianti(item) {
      // Controllo chiusura
      if (!!item.item == false) return;
      if (item.value == false) return;

      // NOTA: se clicco prima sull'espandi riga la funzione selectRow non viene richiamata
      this.progDett = item.item.Prog;

      this.caricaVarianti(this.progDett);
    },

    // --------------------------------------------------------
    // --------------------- Carica dati ---------------------
    // --------------------------------------------------------
    async caricaDati(idPreventivo, scorri) {
      // Reset
      this.pulisciOrdine();

      this.idPrev = idPreventivo;

      // Avvio attesa
      this.$wait.open();

      await this.caricaOrdine(scorri);

      this.fineCaricamentoOrdine();
    },

    async caricaOrdine(scorri) {
      let str = this.idPrev + "/" + this.isOrdine() + "/" + (scorri || "");

      try {
        let res = await get_datiOrdine(str);

        this.datiTestata = res.data.Testata;
        this.datiDettaglio = res.data.Dettaglio;
        this.datiVociExtra = res.data.VociExtra;
        this.datiAllegati = res.data.Allegati;
        this.datiSerie = res.data.Serie;
        this.trasmittanzaLimiti = res.data.TrasmittanzaLimiti;
        this.datiTesti = res.data.Testi;

        if (this.datiTestata) {
          this.idPrev = this.datiTestata.IDPrev;
        }

      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async caricaDettaglio() {
      this.loadingDettaglio = true;

      // Reset
      this.progDett = 0;
      this.selectedRowDettaglio = {};
      this.datiDettaglio = [];
      this.imgDettForceUpdate = Math.random();

      try {
        let res = await get_dettaglioOrdine(this.idPrev + "/" + this.scheda);
        this.datiDettaglio = res.data;

        // Voci extra (serve per i coprifili)
        await this.caricaVociExta(true);

        // Serie
        await this.caricaSerie(true);

        // Totali
        await this.caricaTotali();

        // Ciclo sulle righe espanse (per aggiornare le varianti)
        this.expandedRowsDettaglio.forEach((element) => {
          this.caricaVarianti(element.Prog);
        });

      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.loadingDettaglio = false;
    },

    async caricaVociExta(noTotale) {
      // Reset
      this.progVociExtra = 0;
      this.selectedRowVociExtra = {};

      try {
        let res = await get_vociExtraOrdine(this.idPrev);

        this.datiVociExtra = res.data;

        // Aggiorno i totali
        if (!!noTotale == false) this.caricaTotali();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async caricaAllegati() {
      // Reset
      this.progAllegati = 0;
      this.selectedRowAllegati = {};

      let res = await get_allegatiOrdine(this.idPrev);

      this.datiAllegati = res.data;
    },

    async caricaSerie(noTotale) {
      // Reset
      this.progSerie = 0;
      this.selectedRowSerie = {};

      try {
        let res = await get_riepilogoSerie(this.idPrev);

        this.datiSerie = res.data;

        // Aggiorno i totali
        if (!!noTotale == false) this.caricaTotali();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async caricaVarianti(prog) {
      this.loadingVarianti = true;

      try {
        // Reset (solo prog selezionato per non pulire eventuali altre righe espanse )
        for (let i = this.varianti.length - 1; i >= 0; i--) {
          if (this.varianti[i].Prog == prog) {
            this.varianti.splice(i, 1);
          }
        }

        let res = await get_varianteDettaglio(
          this.idPrev + "/" + this.scheda + "/" + prog
        );

        // Escludo i coprifili
        let arr = res.data.Varianti.filter(
          (f) => f.CodMacroCategoria != "COPRIFILO"
        );

        // Aggiunto le righe
        for (let i = 0; i < arr.length; i++) {
          this.varianti.push(arr[i]);
        }
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.loadingVarianti = false;
    },

    // --------------------------------------------------------
    // ------------------------- Duplica ----------------------
    // --------------------------------------------------------
    async duplicaRigoDettaglio() {
      if (!this.controllaRiga()) return;

      try {
        let res = await dup_dettaglioOrdine(
          this.idPrev + "/" + this.scheda + "/" + this.progDett
        );
        // Ricarico il dettaglio
        await this.caricaDettaglio();

        this.selectRowDettaglio(res.data);

        // Apro la riga
        this.avviaComando("modifica");
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    // --------------------------------------------------------
    // ------------------------- Sposta ----------------------
    // --------------------------------------------------------
    async spostaRigoDettaglio(tipo) {
      if (this.ordineImportato) {
        this.$toast.alert("Ordine importato, impossibile spostare");
        return;
      }

      if (!this.controllaRiga()) return;

      try {
        let res = await get_spostaDettaglio(
          this.idPrev + "/" + this.scheda + "/" + this.progDett + "/" + tipo
        );
        // Ricarico il dettaglio
        await this.caricaDettaglio();

        this.selectRowDettaglio(res.data);
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    // --------------------------------------------------------
    // ------------------------- Elimina ----------------------
    // --------------------------------------------------------

    async eliminaRigoDettaglio() {
      if (!this.controllaRiga()) return;


      let confirm = await this.$confirm.open(
        "Elimina",
        "Vuoi eliminare il rigo selezionato ?"
      );

      if (!confirm) return;

      try {

        // Chiudo la riga espansa (se aperta)
        for (let i = this.expandedRowsDettaglio.length - 1; i >= 0; i--) {
          if (this.expandedRowsDettaglio[i].Prog == this.progDett) {
            this.expandedRowsDettaglio.splice(i, 1);
          }
        }

        let res = await del_dettaglioOrdine(
          this.idPrev + "/" + this.scheda + "/" + this.progDett
        );
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      // Ricarico il dettaglio
      this.caricaDettaglio();
    },

    async eliminaRigoVociExtra() {
      if (!this.controllaRiga()) return;

      // Controllo gestione automatica
      let r = this.datiVociExtra.find((f) => f.Prog == this.progVociExtra);
      if (r && r.Tipo) {
        this.$toast.alert(
          "La riga è gestita automaticamente dal programma, impossibile proseguire"
        );
        return;
      }

      let confirm = await this.$confirm.open(
        "Elimina",
        "Vuoi eliminare il rigo selezionato ?"
      );

      if (!confirm) return;

      try {
        let res = await del_rigoVociExtra(
          this.idPrev + "/" + this.progVociExtra
        );

        // Ricarico il dettaglio
        this.caricaVociExta();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async eliminaRigoAllegati() {
      if (!this.controllaRiga()) return;

      let confirm = await this.$confirm.open(
        "Elimina",
        "Vuoi eliminare il rigo selezionato ?"
      );

      if (!confirm) return;

      let res = await del_allegatiOrdine(this.idPrev + "/" + this.progAllegati);

      // Ricarico i dati
      this.caricaAllegati();
    },

    // --------------------------------------------------------
    // ------------------------ Funzioni ----------------------
    // --------------------------------------------------------
    avviaComando(comando) {
      // Controllo numero
      if (!this.controllaNumero()) return;

      if (this.tabs == 0) {
        // Dettaglio

        this.scheda = 1;

        switch (comando) {
          case "nuovo":
            this.progDett = 0;

            // Apro la dialog
            this.dialogDett = true;

            break;
          case "modifica":
            if (!this.controllaRiga()) return;

            // Apro la dialog
            this.dialogDett = true;

            break;
          case "elimina":
            this.eliminaRigoDettaglio();
            break;
          case "duplica":
            this.duplicaRigoDettaglio();
            break;

          case "spostaSu":
            this.spostaRigoDettaglio("prec");
            break;

          case "spostaGiu":
            this.spostaRigoDettaglio("succ");
            break;
        }

        // -------
      } else if (this.tabs == 1) {
        // Voci extra

        switch (comando) {
          case "nuovo":
            this.progVociExtra = 0;

            // Apro la dialog
            this.dialogVociExtra = true;

            break;
          case "modifica":
            if (!this.controllaRiga()) return;

            // Apro la dialog
            this.dialogVociExtra = true;

            break;
          case "elimina":
            this.eliminaRigoVociExtra();
            break;

          case "duplica":
            // Da fare
            break;
        }
        // -------
      } else if (this.tabs == 2) {
        // Serie

        switch (comando) {
          case "modifica":
            if (!this.controllaRiga()) return;

            // Apro la dialog
            this.dialogSerie = true;

            break;
        }
      } else if (this.tabs == 3) {
        // Allegati
        switch (comando) {
          case "nuovo":
            this.progAllegati = 0;

            // Apro la dialog
            this.dialogAllegati = true;

            break;
          case "modifica":
            if (!this.controllaRiga()) return;

            // Apro la dialog
            this.dialogAllegati = true;

            break;

          case "elimina":
            this.eliminaRigoAllegati();
            break;

          case "duplica":
            // Da fare
            break;
        }
        // -------
      } else if (this.tabs == 4) {
        // Note

        switch (comando) {
          case "salva":
            this.salvaNote();
            break;
          case "elimina":
            // Non gestito
            break;
        }
      }
    },

    controllaNumero(noVerificaCliente) {
      if (!!this.datiTestata.NumPrev == false) {
        this.$toast.alert("Nessun numero selezionato");

        return false;
      }

      // Controllo presenza cliente
      if (
        !!noVerificaCliente == false &&
        !!this.datiTestata.CodCliente == false
      ) {
        this.$toast.alert("Cliente non valido");
        return false;
      }

      return true;
    },

    controllaRiga() {
      if (this.tabs == 0) {
        // Dettaglio
        if (this.progDett != 0) return true;
      } else if (this.tabs == 1) {
        // Voci extra
        if (this.progVociExtra != 0) return true;
      } else if (this.tabs == 2) {
        // Serie
        if (this.progSerie != 0) return true;
      } else if (this.tabs == 3) {
        // Allegati
        if (this.progAllegati != 0) return true;
      }

      this.$toast.alert("Nessuna riga selezionata");
      return false;
    },

    fineCaricamentoOrdine() {
      // Fine attesa
      setTimeout(() => {
        this.$wait.close();
      }, 300);
    },

    refresh() {
      if (this.idPrev == 0) return;

      this.caricaDati(this.idPrev);
    },

    selectRow(item) {
      if (this.tabs == 0) {
        // Dettaglio
        this.selectedRowDettaglio = item;
        this.progDett = item.Prog;
      } else if (this.tabs == 1) {
        // Voci extra
        this.selectedRowVociExtra = item;
        this.progVociExtra = item.Prog;
      } else if (this.tabs == 2) {
        // Serie
        this.selectedRowSerie = item;
        this.progSerie = item.Prog;
      } else if (this.tabs == 3) {
        // Allegati
        this.selectedRowAllegati = item;
        this.progAllegati = item.Prog;
      }
    },

    selectRowDettaglio(prog) {
      // Ricavo la riga selezionata
      let rigaSel = this.datiDettaglio.find(
        (f) => f.Scheda == this.scheda && f.Prog == prog
      );

      // Nel caso di nuova riga non seleziono
      if (rigaSel) {
        // Seleziono la riga
        this.selectRow(rigaSel);
      }

      // Scroll verso riga selezionata
      // NOTA: la funzione selectRow imposta la var selectedRowDettaglio
      if (rigaSel && this.selectedRowDettaglio) {
        try {
          let self = this

          this.$nextTick(() => {
            self.$refs[self.selectedRowDettaglio.Prog].scrollIntoView(false);
          });

        } catch (error) {
          console.log(error)
        }

      }
    },

    imgShowVariantiDettaglio(isExpanded) {
      return isExpanded ? "keyboard_arrow_down" : "keyboard_arrow_right";
    },

    setImage(path) {
      return path + "?" + this.imgDettForceUpdate;

      // NOTA: usando questa istruzione,le immagini venivano sempre aggiornate
      //       perchè è presente l'istruzione Math.random() che ne imponeva la rivalutazione
      //return path + "?" + Math.random();
    },

    keyEvent(event) {
      //console.log(event);

      if (event.ctrlKey && event.keyCode == 73) alert(this.idPrev);
    },

    getTipoDoc() {
      return this.$route.name.substring(0, 3).toUpperCase();
    },

    getLimiteTrasmittanza() {
      let res = this.trasmittanzaLimiti.find(
        (f) =>
          f.ZonaClimatica == this.datiTestata.ZonaClimatica &&
          f.Tipologia == this.datiTestata.TipoLavoro
      );

      return res;
    },

    checkValoreLimite(uw) {
      let res = this.getLimiteTrasmittanza();

      if (res && uw !== 0) {
        return uw > res.Uw ? "#FF4500" : "#90EE90";
      }

      return "transparent ";
    },

    getLabelPrint() {
      let res = this.getLimiteTrasmittanza();

      if (res) return "Zona " + res.ZonaClimatica + " - Uw " + res.Uw;

      return "";
    },

    checkInvioMail() {
      return (
        this.getRole.toLowerCase() === "Interno".toLowerCase() ||
        this.getRole.toLowerCase() === "AgenteInterno".toLowerCase()
      );
    },

    isOrdine() {
      return this.$route.path.includes("ordini");
    },

    testi() {
      this.dialogTesti = true;
    },

    cambioListino() {
      // Controllo numero
      if (!this.controllaNumero()) return;

      this.dialogListino = true;
    },

    abbinaOrdineInfoAzienda() {
      // Controllo numero
      if (!this.controllaNumero()) return;

      this.dialogAbbinaInfoAzienda = true;
    },

    getStatoOrdine(tipo) {
      if (tipo == "icona") {
        if (this.statoOrdine == "Accettato") return "check";
        else if (this.statoOrdine == "In attesa") return "hourglass_empty";
        else if (this.statoOrdine == "Annullato") return "remove_done";
      } else if (tipo == "colore") {
        if (this.statoOrdine == "Accettato") return "#00FF00";
        else if (this.statoOrdine == "In attesa") return "#FFA841";
        else if (this.statoOrdine == "Annullato") return "#FFA841";
      } else if (tipo == "testo") {
        if (this.statoOrdine == "Accettato") return this.datiTestata.NumOrdine;
        else return this.statoOrdine;
      }

      return "";
    },

    apriTimeLine() {
      this.dialogTimeline = true;
    },

    apriImpostazioni() {
      this.dialogImpostazioni = true;
    },

    async convertiPrevInOrdine() {
      let confirm = await this.$confirm.open(
        "Converti ordine",
        "Vuoi trasformare il preventivo in ordine  ?"
      );

      if (!confirm) return;

      try {
        let res = await set_convertiOrdine(this.idPrev);

        // Apro l'ordine
        this.$router.push({ path: "/ordini" });

        await this.caricaDati(this.idPrev, "");

        this.abbinaOrdineInfoAzienda();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async annullaInvio() {
      let confirm = await this.$confirm.open(
        "Annulla",
        "Vuoi annullare l'invio ?"
      );

      if (!confirm) return;

      let res = await get_annullaConfermaOrdine(this.idPrev);

      if (res.data != "OK") {
        this.$toast.alert(res.data);
        return;
      }

      this.refresh();
    },

    // --------------------------------------------------------
    // --------------------- Close Dialog ---------------------
    // --------------------------------------------------------
    async closeDialogDettaglio(aggiorna) {
      this.dialogDett = false;

      // Controllo se aggiornare
      if (aggiorna) {
        // Memorizzo il prog selezionato (prima di carica dettaglio perchè verrebbe azzerato)
        let progSwap = this.progDett;

        // Carico i dati
        await this.caricaDettaglio();

        // Seleziono la riga
        this.selectRowDettaglio(progSwap);
      }
    },
    closeDialogCerca(idPrev) {
      this.dialogCerca = false;

      if (idPrev) this.caricaDati(idPrev);
    },
    closeDialogVociExtra(aggiorna) {
      this.dialogVociExtra = false;

      // Controllo se aggiornare
      if (aggiorna) this.caricaVociExta();
    },
    closeDialogAllegati(aggiorna) {
      this.dialogAllegati = false;

      // Controllo se aggiornare
      if (aggiorna) this.caricaAllegati();
    },
    closeDialogCercaClienti(codCliente) {
      this.dialogCercaClienti = false;

      // Controllo prenseza preventivo
      // NOTA: la videata dei clienti possono aprirla anche da utilita
      if (!!this.datiTestata.NumPrev == false)
        return

      // Se ho selezionato il cliente lo salvo sul preventivo
      if (codCliente) {
        this.datiTestata.CodCliente = codCliente;

        this.salvaTestata();
      }
    },
    closeDialogSconti(aggiorna) {
      this.dialogSconti = false;

      // Controllo se aggiornare
      if (aggiorna) this.caricaDettaglio();
    },
    closeDialogSerie(aggiorna) {
      this.dialogSerie = false;

      // Controllo se aggiornare
      if (aggiorna) this.caricaDettaglio();
    },
    closeDialogCosto() {
      this.dialogCosto = false;
    },
    closeDialogDatiTestata(aggiorna) {
      this.dialogDatiTestata = false;

      if (aggiorna) this.refresh();
    },
    closeDialogGeneraOrd(aggiorna) {
      this.dialogGeneraOrdine = false;

      if (aggiorna) this.refresh();
    },
    closeDialogMail(aggiorna) {
      this.dialogMail = false;

      if (aggiorna) this.refresh();
    },
    closeDialogTesti() {
      this.dialogTesti = false;
    },
    closeListino(aggiorna) {
      this.dialogListino = false;

      if (aggiorna) this.refresh();
    },
    closeDialogAbbina(aggiorna) {
      this.dialogAbbinaInfoAzienda = false;

      if (aggiorna) this.refresh();
    },

    closeTimeline() {
      this.dialogTimeline = false;
    },

    closeImpostazioni() {
      this.dialogImpostazioni = false;
    },
  },

  mounted() {
    // Inizializzo
    this.init();

    // Controllo numero passato da ordini inviati
    let idPrevSwap = this.$store.getters["user/getIdPrev"];

    if (idPrevSwap) {
      this.caricaDati(idPrevSwap, "");

      // Reset
      this.$store.commit("user/SET_IDPREV", 0);
    }
  },

  created() {
    window.addEventListener("keydown", this.keyEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyEvent);
  },

  watch: {
    $route(to, from) {
      this.pulisciOrdine();

      // Inizializzo
      this.init();
    },
  },

  computed: {
    messaggioAlert() {

      // Controllo utente 
      if (this.utenteInterno) {
        //-- UTENTE INTERNO

        if (this.ordineGenerato || this.preventivoImportato)
          return "Ordine generato, le modifiche apportate non saranno applicate all'ordine."

        if (this.preventivoSoloInviato)
          return " Preventivo inviato (non importato), è ancora possibile effettuare modifiche."
      }
      else {
        // -- UTENTE ESTERNO

        if (this.preventivoSoloInviato)
          return " Preventivo inviato, non ancora importato, è possibile annullare l'invio."
      }

      if (this.bloccato)
        return "Ordine generato " + (this.datiTestata.NumOrdine || '') + " impossibile applicare modifiche."

      return ""

    },

    bloccato() {
      // Nel caso di utente interno possono modificare 
      if (this.utenteInterno) return false;

      if (this.datiTestata == null)
        return true


      // Nel caso di preventivo NON possono modificare se è già stato inviato 
      if (this.datiTestata.Ordine == false && this.datiTestata.OrdineInviato) {
        return true;
      }

      // Preventivo\Ordine NON può essere modificato se importato
      return this.datiTestata.OrdineImportato;
    },

    preventivoSoloInviato() {
      return (
        this.datiTestata.Ordine == false &&
        this.datiTestata.OrdineGenerato == false &&
        this.datiTestata.OrdineImportato == false &&
        this.datiTestata.OrdineInviato
      );
    },


    preventivoImportato() {
      // Nel caso di utente interno non vedono avvisi
      if (this.utenteInterno) return false;

      // Controllo preventivo
      if (this.datiTestata.Ordine == false && this.datiTestata.OrdineImportato)
        return true;

      return false;
    },

    ordineGenerato() {
      return this.datiTestata.OrdineGenerato;
    },

    ordineInviato() {
      return this.datiTestata.OrdineInviato;
    },

    ordineImportato() {
      return this.datiTestata.OrdineImportato;
    },


    listinoObsoleto() {
      return Object.keys(this.datiTestata).length > 0 && this.datiTestata.ListinoInVigore == false
    },

    visAvvisoListinoObsoleto() {
      return this.listinoObsoleto && this.datiTestata.DisabilitaAvvisoListino == false
    },

    descAvvisoListino() {
      return this.datiTestata.DescrizioneAvvisoWeb;
    },

    statoOrdine() {
      if (this.datiTestata.Ordine) {
        if (this.datiTestata.OrdineImportato) return "Accettato";
        else if (this.datiTestata.OrdineAnnullato) return "Annullato";
        else return "In attesa";
      } else return "";
    },

    getData() {
      if (Object.keys(this.datiTestata).length == 0) return "";

      return this.$i2kFunctions.formatISODate(this.datiTestata.Data);
    },

    getRole() {
      return this.$store.getters["user/getRole"];
    },

    utenteInterno() {
      return (
        this.getRole.toLowerCase() === "Interno".toLowerCase() ||
        this.getRole.toLowerCase() === "AgenteInterno".toLowerCase()
      );
    },

    utenteObsoleto() {
      return this.$store.getters["user/getObsoleto"];
    },

    nomeUtente() { return this.$store.getters["user/getUser"]; },

    utenteDemo() {
      return this.nomeUtente.toString().toUpperCase() == 'BERGERO'
    },

    rigaDettZero() {

      if (!!this.datiDettaglio && Object.keys(this.datiDettaglio).length > 0) {
        // Controllo presenza prog zero
        for (let i = this.datiDettaglio.length - 1; i >= 0; i--) {
          if (this.datiDettaglio[i].Prog == 0) {
            return true
          }
        }
      }

      return false
    },


    testoInizio: {
      get() {
        let res = this.datiTesti.find((f) => f.Tipo == "Inizio");
        return res ? res.Descrizione : "";
      },
      set(val) {
        let res = this.datiTesti.find((f) => f.Tipo == "Inizio");

        if (res) res.Descrizione = val;
        else this.datiTesti.push({ Tipo: "Inizio", Descrizione: val });
      },
    },

    testoFine: {
      get() {
        let res = this.datiTesti.find((f) => f.Tipo == "Fine");
        return res ? res.Descrizione : "";
      },
      set(val) {
        let res = this.datiTesti.find((f) => f.Tipo == "Fine");

        if (res) res.Descrizione = val;
        else this.datiTesti.push({ Tipo: "Fine", Descrizione: val });
      },
    },

    getAllegati() {
      return this.datiAllegati.filter((f) => f.Ordine == false);
    },

    ordineElaboratoProduzione() {

      if (!!this.datiDettaglio && Object.keys(this.datiDettaglio).length > 0) {
        // Controllo presenza prog zero
        for (let i = this.datiDettaglio.length - 1; i >= 0; i--) {
          if (this.datiDettaglio[i].ProgElaborazione != 0) {
            return true
          }
        }
      }

      return false
    },

  },
};
</script>

<style scoped>
/* Tabella dettaglio */
table.v-table td:not(:last-child) {
  border-right: 1px solid lightgray;
}

/* Tabella varianti */
table.tableVarianti {
  border-spacing: 0px;
  width: 100%;
  font-size: 12px;
}

table.tableVarianti td,
table.tableVarianti th {
  height: 20px;
  border-right: 0px !important;
  border-bottom: 1px solid lightgray !important;
}

table.tableVarianti th {
  font-weight: bold;
  font-size: 12px;
}
</style>
